@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

html {
  margin: 0;
  padding: 0;
  line-height: 0;
}

body {
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: normal;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.prevent-scroll {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}