div.about-container {
  padding: 25px 25px 75px;
}

div.about-container h1 {
  font-size: 2.5em;
  font-weight: 200;
  text-align: center;
}

div.about-container p strong {
  font-weight: 500;
}

div.about-container p strong.cv-head {
  display: block;
  padding: 25px 0 0;
}

div.about-container a,
div.about-container a:visited {
  color: #00aeef;
  text-decoration: none;
  transition: all 0.5s;
}

div.about-container a:hover {
  color: #ec008c;
}

div.about-container ul {
  margin-bottom: 35px;
}

/* div.about-container a::after {
  content: '>';
  display: inline-block;
  font-size: .7em;
  padding-left: 3px;
} */


/* Desktop */

@media(min-width: 750px) {
  div.about-container {
    padding: 0 0 50px 0;
    max-width: 700px;
    margin: 0 auto;
  }

  div.about-container > h1 {
    display: none;
  }
}