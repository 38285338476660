div.work-container-container {
  padding: 5px 5px 75px;
}

div.work-container-container h1 {
  font-size: 2.5em;
  font-weight: 200;
  text-align: center;
  margin-bottom: 25px;
}

div.work-container-container div.carousel {
  width: 100%;
}

div.work-container-container div.carousel picture,
div.work-container-container div.carousel picture img {
  height: auto;
  width: 100%;
}


/* Desktop */

@media(min-width: 750px) {
  div.work-container-container {
    padding: 0 0 0 10px;
    margin: 0 auto;
  }

  div.work-container-container > h1 {
    display: none;
  }

  div.work-container-container div.carousel {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 75vh;
    white-space: nowrap;
  }

  div.work-container-container div.carousel picture,
  div.work-container-container div.carousel picture img {
    height: 100%;
    width: auto;
  }
  
  div.work-container-container div.carousel picture {
    margin-right: 10px;
  }
}