@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
html {
  margin: 0;
  padding: 0;
  line-height: 0;
}

body {
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: normal;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.prevent-scroll {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}
.App {
  padding: 0;
  margin: 0;
}
div.home-container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #ffffff;
  text-align: center;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  background-color: #00aeef;
  background-size: cover;
  background-position: center;
}

div.home-container * {
  align-self: center;
}

div.home-container h1 {
  padding: 0;
  margin: 0 0 5px 0;
  font-size: 62px;
  font-weight: 300;
}

div.home-container p {
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  font-size: 25px;
  line-height: 1.2;
}
div.about-container {
  padding: 25px 25px 75px;
}

div.about-container h1 {
  font-size: 2.5em;
  font-weight: 200;
  text-align: center;
}

div.about-container p strong {
  font-weight: 500;
}

div.about-container p strong.cv-head {
  display: block;
  padding: 25px 0 0;
}

div.about-container a,
div.about-container a:visited {
  color: #00aeef;
  text-decoration: none;
  transition: all 0.5s;
}

div.about-container a:hover {
  color: #ec008c;
}

div.about-container ul {
  margin-bottom: 35px;
}

/* div.about-container a::after {
  content: '>';
  display: inline-block;
  font-size: .7em;
  padding-left: 3px;
} */


/* Desktop */

@media(min-width: 750px) {
  div.about-container {
    padding: 0 0 50px 0;
    max-width: 700px;
    margin: 0 auto;
  }

  div.about-container > h1 {
    display: none;
  }
}
div.work-container-container {
  padding: 5px 5px 75px;
}

div.work-container-container h1 {
  font-size: 2.5em;
  font-weight: 200;
  text-align: center;
  margin-bottom: 25px;
}

div.work-container-container div.carousel {
  width: 100%;
}

div.work-container-container div.carousel picture,
div.work-container-container div.carousel picture img {
  height: auto;
  width: 100%;
}


/* Desktop */

@media(min-width: 750px) {
  div.work-container-container {
    padding: 0 0 0 10px;
    margin: 0 auto;
  }

  div.work-container-container > h1 {
    display: none;
  }

  div.work-container-container div.carousel {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 75vh;
    white-space: nowrap;
  }

  div.work-container-container div.carousel picture,
  div.work-container-container div.carousel picture img {
    height: 100%;
    width: auto;
  }
  
  div.work-container-container div.carousel picture {
    margin-right: 10px;
  }
}
div.navigation-container {
  left: 0;
  bottom: 0;
  z-index: 20;
  width: 100%;
  height: 50px;
  display: flex;
  text-align: center;
  font-size: 3em;
  position: fixed;
  overflow: hidden;
  flex-flow: column;
  text-align: center;
  transition: all 0.5s;
  background: #333333;
}

div.navigation-container * {
  align-self: center;
}

div.navigation-container.open {
  height: 100%;
}

div.navigation-container div.links a,
div.navigation-container div.links a:visited {
  display: block;
  margin-bottom: 15px;
  color: #00aeef;
  text-decoration: none;
}

div.navigation-container div.links a.active {
  color: #ec008c;
  pointer-events: none;
}

div.navigation-container div.links {
  position: absolute;
  bottom: 90px;
}

div.navigation-container.open div.links a,
div.navigation-container.open div.links div {
  opacity: 0;
}

div.navigation-container.open div.links a:nth-of-type(1) {
  -webkit-animation: fadeIn 0.5s 0.2s forwards;
          animation: fadeIn 0.5s 0.2s forwards;
}

div.navigation-container.open div.links a:nth-of-type(2) {
  -webkit-animation: fadeIn 0.5s 0.25s forwards;
          animation: fadeIn 0.5s 0.25s forwards;
}

div.navigation-container.open div.links a:nth-of-type(3) {
  -webkit-animation: fadeIn 0.5s 0.375s forwards;
          animation: fadeIn 0.5s 0.375s forwards;
}

div.navigation-container.open div.links a:nth-of-type(4) {
  -webkit-animation: fadeIn 0.5s 0.5s forwards;
          animation: fadeIn 0.5s 0.5s forwards;
}

div.navigation-container.open div.links a:nth-of-type(5) {
  -webkit-animation: fadeIn 0.5s 0.625s forwards;
          animation: fadeIn 0.5s 0.625s forwards;
}

div.navigation-container.open div.links div:nth-of-type(1) {
  -webkit-animation: fadeIn 0.5s 0.75s forwards;
          animation: fadeIn 0.5s 0.75s forwards;
}

div.navigation-container div.navbtn {
  bottom: 0;
  z-index: 25;
  width: 50px;
  height: 50px;
  position: absolute;
  display: flex;
  justify-content: center;
}

div.navigation-container .desktop {
  display: none;
}

div.navigation-container div.navbtn img {
  width: 50%;
  align-self: center;
  position: absolute;
  transition: all 0.5s;
}

div.navigation-container div.navbtn img.h-icon,
div.navigation-container div.navbtn img.x-icon {
  opacity: 0;
}

div.navigation-container div.navbtn img.h-icon.on,
div.navigation-container.open div.navbtn img.x-icon,
div.navigation-container div.navbtn img.x-icon.on {
  opacity: 1;
}

div.navigation-container.open div.navbtn img.b-icon,
div.navigation-container div.navbtn img.b-icon.off,
div.navigation-container div.navbtn img.h-icon.on + img.b-icon,
div.navigation-container div.navbtn img.x-icon.on + img.b-icon {
  opacity: 0;
}

div.navigation-container div.social-icons {
  display: flex;
  margin-top: 35px;
  flex-direction: row;
  justify-content: center;
}

div.navigation-container div.social-icons a {
  display: inherit;
  margin: 0 !important;
  padding: 0 !important;
}

div.navigation-container div.social-icons img {
  width: 20px;
  margin: 0 15px;
  align-self: center;
}



/* DESKTOP */

@media(min-width: 750px) {
  div.navigation-container {
    left: 50px;
    top: 25px;
    width: calc(100% - 100px);
    height: 50px;
    display: flex;
    text-align: center;
    font-size: 3em;
    position: fixed;
    overflow: unset;
    flex-flow: row;
    text-align: left;
    transition: none;
    background: transparent;
  }
  
  div.navigation-container * {
    align-self: flex-start;
  }

  div.navigation-container.open {
    height: 50px;
  }

  div.navigation-container div.links > a:first-of-type {
    display: none;
  }

  div.navigation-container div.links a,
  div.navigation-container div.links a:visited {
    display: block;
    margin: 0 25px;
    color: #00aeef;
    transition: all 0.5s;
    text-decoration: none;
  }

  div.navigation-container.home div.links a,
  div.navigation-container.home div.links a:visited {
    color: #ffffff;
  }

  div.navigation-container div.links a:hover {
    color: #ec008c;
  }

  div.navigation-container.home div.links a:hover {
    /* color: #00aeef; */
    color: #ec008c;
  }

  div.navigation-container div.links a.active {
    color: #ec008c;
    font-weight: 500;
    pointer-events: none;
  }

  div.navigation-container div.links {
    width: calc(100% - 50px);
    height: 50px;
    left: 50px;
    bottom: unset;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 24px;
  }

  div.links.hide-nav {
    opacity: .125;
    pointer-events: none;
  }

  div.navigation-container div.links * {
    align-self: center;
  }

  div.navigation-container.open div.links a,
  div.navigation-container.open div.links div {
    opacity: 1;
  }

  div.navigation-container.open div.links div:nth-of-type(1),
  div.navigation-container.open div.links a:nth-of-type(1),
  div.navigation-container.open div.links a:nth-of-type(2),
  div.navigation-container.open div.links a:nth-of-type(3),
  div.navigation-container.open div.links a:nth-of-type(4) {
    -webkit-animation: unset;
            animation: unset;
  }

  div.navigation-container div.navbtn {
    bottom: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    display: flex;
    justify-content: center;
    background: #000000;
  }
  
  div.navigation-container .mobile {
    display: none;
  }
  
  div.navigation-container .desktop {
    display: unset;
  }

  div.navigation-container div.navbtn img {
    width: 50%;
    align-self: center;
    position: absolute;
    opacity: 0;
    transition: all 0.5s;
  }

  div.navigation-container.open div.navbtn img.x-icon,
  div.navigation-container div.navbtn img.h-icon.on,
  div.navigation-container div.navbtn img.h-icon,
  div.navigation-container div.navbtn img.x-icon,
  div.navigation-container.open div.navbtn img.x-icon.on,
  div.navigation-container div.navbtn img.x-icon.on {
    opacity: 0;
  }
  
  div.navigation-container div.navbtn img.b-icon.off,
  div.navigation-container div.navbtn img.x-icon.on + img.b-icon.off,
  div.navigation-container.open div.navbtn img.x-icon.on + img.b-icon.off,
  div.navigation-container div.navbtn img.x-icon.on + img.b-icon,
  div.navigation-container div.navbtn img.h-icon.on + img.b-icon,
  div.navigation-container.open div.navbtn img.b-icon,
  div.navigation-container div.navbtn img.b-icon {
    opacity: 1;
    cursor: pointer;
  }


  div.navigation-container div.social-icons {
    display: flex;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    right: 0;
  }

  div.navigation-container div.social-icons a {
    display: inherit;
    margin: 0 !important;
    padding: 0 !important;
  }

  div.navigation-container div.social-icons img {
    width: 20px;
    margin: 0 0 0 15px;
    align-self: center;
  }
  
  div.navigation-container.home div.social-icons img {
    transition: all 0.5s;
  }

  div.navigation-container.home div.social-icons img {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
  }

  div.navigation-container div.social-icons img:hover {
    -webkit-filter: hue-rotate(-228deg);
            filter: hue-rotate(-228deg);
  }

  div.navigation-container.portraits,
  div.navigation-container.fashion,
  div.navigation-container.other,
  div.navigation-container.about {
    position: relative;
    margin-bottom: 100px;
  }
}







@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}







@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
